



























































































































































.studentInfoCertificationRecord_6 {
  height: 100%;

  .topCz {
    overflow: hidden;
    margin-bottom: 20px;
    .el-button {
      float: right;
      margin-left: 10px;
    }
  }

  .shells {
    height: 80%;
    overflow-y: auto;

    .shell {
      width: 120px;
      height: 120px;
      float: left;
      margin-right: 15px;
      margin-bottom: 15px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }
      .shell_time {
        font-size: 12px;
      }

      .el-checkbox {
        position: absolute;
        right: 0;
        top: -1px;
      }
    }
  }

  .pagination {
    clear: both;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
