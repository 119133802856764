







































































































































































































































.studentInfoCertificationRecord_1 {
  height: 100%;
  .topCz {
    overflow: hidden;
    margin-bottom: 20px;
    .el-button {
      float: right;
      margin-left: 10px;
    }
  }
  .shells {
    height: 80%;
    overflow-y: auto;
    .shell {
      width: 100px;
      height: 100px;
      float: left;
      margin-right: 15px;
      margin-bottom: 15px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .el-checkbox {
        position: absolute;
        right: 0;
        top: -1px;
      }
    }
  }

  .pageNum {
    clear: both;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
