.studentInfoCertificationRecord_5 {
  height: 100%;
}
.studentInfoCertificationRecord_5 .topCz {
  overflow: hidden;
  margin-bottom: 20px;
}
.studentInfoCertificationRecord_5 .topCz .el-button {
  float: right;
  margin-left: 10px;
}
.studentInfoCertificationRecord_5 .shells {
  height: 80%;
  overflow-y: auto;
}
.studentInfoCertificationRecord_5 .shells .shell {
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
  position: relative;
}
.studentInfoCertificationRecord_5 .shells .shell img {
  width: 100%;
  height: 100%;
}
.studentInfoCertificationRecord_5 .shells .shell .el-checkbox {
  position: absolute;
  right: 0;
  top: -1px;
}
.studentInfoCertificationRecord_5 .pagination {
  clear: both;
  position: absolute;
  bottom: 0;
  right: 0;
}
