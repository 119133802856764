












































































































































































































































































































































.faceCheck {
    display: flex;
    justify-content: space-between;
}
.remindTemplate{
    .el-radio{
        margin-bottom: 10px;
    }
}
